import React from "react";
import MainLayout from "../layout/main-layout";

const LegalScreen = () => (
  <MainLayout>
    <h1>Impressum</h1>
    <strong>Snash UG (haftungsbeschränkt)</strong>
    <br />
    Otto-Hahn-Str. 7<br />
    68623 Lampertheim Deutschland
    <br />
    <strong>Mail</strong>{" "}
    <a className='link' href='mailto:hello@snash.eu'>
      hello@snash.eu
    </a>
    <br />
    <br />
    <strong>Geschäftsführer</strong> Maximilian Hamm und Korbinian Klein
    <br />
    Hessen Amtsgericht Darmstadt 95547
    <br />
    <br />© 2021 snash UG
  </MainLayout>
);

export default LegalScreen;
