import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Root = styled.div`
  padding: 30px 20px;
  display: flex;
  align-items: center;
`;
const Path = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  text-transform: capitalize;
`;

const Header = () => (
  <Root>
    <Path to='/'>Home</Path>
    <Path to='/games'>Spiele</Path>
    <Path to='/legal'>Impressum</Path>
  </Root>
);

export default Header;
