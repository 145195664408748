import React, { ReactNode } from "react";
import styled from "styled-components";
import Header from "../components/header";
import { Box } from "@rebass/grid";

const Root = styled.div`
  height: 100%;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 300;

  font-size: calc(14px + 11 * (100vw - 300px) / 1300);
  @media (min-width: 1024px) {
    font-size: 25px;
  }
  h1 {
    letter-spacing: 1.857px;
    font-size: 6.9vw;
    @media (min-width: 1024px) {
      font-size: 3em;
    }
  }

  a.link {
    padding-bottom: 2px;
    border-bottom: 3px solid rgba(255, 255, 255, 0.1);
    font-weight: 600;
    transition: border 0.3s ease-in-out;
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    border-color: #fbb03b;
    opacity: 0.9;
  }
`;

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 30px;
`;

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => (
  <Root>
    <Header />
    <Wrapper>
      <Box py='1vw'>{children}</Box>
    </Wrapper>
  </Root>
);

export default MainLayout;
