import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./pages/home";
import LegalScreen from "./pages/legal";
import GamesScreen from "./pages/games";

const App = () => (
  <Router>
    <Switch>
      <Route path='/legal'>
        <LegalScreen />
      </Route>
      <Route path='/games'>
        <GamesScreen />
      </Route>
      <Route path='/'>
        <HomeScreen />
      </Route>
    </Switch>
  </Router>
);

export default App;
