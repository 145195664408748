import React from "react";
import styled from "styled-components";
import MainLayout from "../layout/main-layout";

const Header = styled.img`
  width: 100%;
  max-width: 500px;
  display: block;
  margin-bottom: 40px;
`;

const GamesScreen = () => (
  <MainLayout>
    <h1>Spiele</h1>
    <Header src='assets/apps.png' />
    Unsere Spiele findest du im{" "}
    <a
      className='link'
      href='https://play.google.com/store/apps/dev?id=7225666796419200068'
      target='_blank'
      rel='noreferrer'
    >
      Play Store
    </a>{" "}
    und{" "}
    <a
      className='link'
      href='https://apps.apple.com/de/developer/snash-ug-haftungsbeschraenkt/id1133559560'
      target='_blank'
      rel='noreferrer'
    >
      App Store
    </a>
  </MainLayout>
);

export default GamesScreen;
