import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import MainLayout from "../layout/main-layout";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20vh 0;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 600px;
`;

const Badge = styled.img`
  width: 100%;
  max-width: 160px;
`;

const HomeScreen = () => (
  <MainLayout>
    <Root>
      <Logo src='assets/logo.svg' />
      <Flex>
        <a
          href='https://play.google.com/store/apps/dev?id=7225666796419200068'
          target='_blank'
          rel='noreferrer'
        >
          <Badge src='/assets/play-store.png' />
        </a>
        <Box width='15px' />
        <a
          href='https://apps.apple.com/de/developer/snash-ug-haftungsbeschraenkt/id1133559560'
          target='_blank'
          rel='noreferrer'
        >
          <Badge src='/assets/app-store.png' />
        </a>
      </Flex>
    </Root>
  </MainLayout>
);

export default HomeScreen;
